<script>
import StoreDiscountList from '@app/components/StoreDiscountList.vue';
import StoreAddDiscountForm from '@app/components/StoreAddDiscountForm.vue';

export default {
  components: {
    StoreDiscountList,
    StoreAddDiscountForm,
  },
  head: {
    title: 'Codes Promo',
  },
};
</script>

<template>
  <div>
    <StoreAddDiscountForm />
    <StoreDiscountList />
  </div>
</template>
